<template>
    <div class="container welcome">
        <div class="flex-row box">
            <div class="left-box flex-column">
                <div class="left-box__top bg-white box-radius padded-10 flex-column">
                    <div class="box-title">
                        <div class="flag"></div>
                        <span>基本信息</span>
                    </div>
                    <div class="order-item flex-row margin-t-10">
                        <span class="color-6">当前站点：</span>
                        <span>{{ data.base.site }}</span>
                    </div>
                    <div class="order-item flex-row">
                        <span class="color-6">手续费率：</span>
                        <span class="color-danger">{{ data.base.service }}%</span>
                    </div>
                    <div class="order-item flex-row">
                        <span class="color-6">累积佣金：</span>
                        <span class="color-danger">{{ data.base.income }}元</span>
                    </div>
                    <div class="order-item flex-row">
                        <span class="color-6">账户余额：</span>
                        <span class="color-danger">{{ data.base.balance }}元</span>
                    </div>
                    <div class="order-item flex-row">
                        <span class="color-6">在提现中：</span>
                        <span class="color-danger">{{ data.base.withdrawal }}元</span>
                    </div>
                </div>
            </div>
            <div class="right-box flex-column">
                <div class="right-box__top flex-row item-center">
                    <div class="item-box bg-white box-radius padded-10 flex-1 flex-column">
                        <div class="box-title">
                            <div class="flag"></div>
                            <span>达人数量</span>
                        </div>
                        <div class="margin-t-15 text-center font-size-30">
                            <span>{{ data.order.member }}</span>
                            <span class="color-grey font-size-12 margin-l-15">(位)</span>
                        </div>
                    </div>
                    <div class="item-box bg-white box-radius padded-10 flex-1 flex-column">
                        <div class="box-title">
                            <div class="flag"></div>
                            <span>加盟佣金</span>
                        </div>
                        <div class="margin-t-15 text-center font-size-30">
                            <span>{{ Number(data.order.commission).toFixed(2) }}</span>
                            <span class="color-grey font-size-12 margin-l-15">(元)</span>
                        </div>
                    </div>
                    <div class="item-box bg-white box-radius padded-10 flex-1 flex-column">
                        <div class="box-title">
                            <div class="flag"></div>
                            <span>达人分成</span>
                        </div>
                        <div class="margin-t-15 text-center font-size-30">
                            <span>{{ Number(data.order.member_commission).toFixed(2) }}</span>
                            <span class="color-grey font-size-12 margin-l-15">(元)</span>
                        </div>
                    </div>
                    <div class="item-box bg-white box-radius padded-10 flex-1 flex-column">
                        <div class="box-title">
                            <div class="flag"></div>
                            <span>平台收益</span>
                        </div>
                        <div class="margin-t-15 text-center font-size-30">
                            <span>{{ Number(data.order.platform_commission).toFixed(2) }}</span>
                            <span class="color-grey font-size-12 margin-l-15">(元)</span>
                        </div>
                    </div>
                </div>
                <div class="right-box__order bg-dark box-radius padded-10 margin-t-10">
                    <div class="box-title">
                        <div class="flag"></div>
                        <span class="color-white">商家信息</span>
                    </div>
                    <div class="margin-t-15 flex-row item-center">
                        <div class="flex-1 flex-column item-center right-box__order-item">
                            <span class="num">{{ data.merchant.all }}</span>
                            <span class="title font-size-12">商家总数</span>
                        </div>
                        <div class="cut"></div>
                        <div class="flex-1 flex-column item-center right-box__order-item">
                            <span class="num">{{ data.merchant.sharing }}</span>
                            <span class="title font-size-12">进行中</span>
                        </div>
                        <div class="cut"></div>
                        <div class="flex-1 flex-column item-center right-box__order-item">
                            <span class="num">{{ data.merchant.welling }}</span>
                            <span class="title font-size-12">预热中</span>
                        </div>
                        <div class="cut"></div>
                        <div class="flex-1 flex-column item-center right-box__order-item">
                            <span class="num">{{ data.merchant.done }}</span>
                            <span class="title font-size-12">已完结</span>
                        </div>
                        <div class="cut"></div>
                        <div class="flex-1 flex-column item-center right-box__order-item">
                            <span class="num">{{ data.merchant.set_meal }}</span>
                            <span class="title font-size-12">套餐总数</span>
                        </div>
                        <div class="cut"></div>
                        <div class="flex-1 flex-column item-center right-box__order-item">
                            <span class="num">{{ data.merchant.shared }}</span>
                            <span class="title font-size-12">已转发</span>
                        </div>
                        <div class="cut"></div>
                        <div class="flex-1 flex-column item-center right-box__order-item">
                            <span class="num">{{ data.merchant.share }}</span>
                            <span class="title font-size-12">可转发</span>
                        </div>
                    </div>
                </div>
                <div class="right-box__area bg-white box-radius padded-10 margin-t-10">
                    <div class="box-title">
                        <div class="flag"></div>
                        <span class="">达人提现信息</span>
                    </div>
                    <div class="margin-t-15 flex-row item-center">
                        <div class="flex-1 flex-row">
                            <span class="color-9 font-size-12">提现笔数(笔)：</span>
                            <span class="font-size-18">{{ data.withdrawal.count }}</span>
                        </div>
                        <div class="flex-1 flex-row">
                            <span class="color-9 font-size-12">提现金额(元)：</span>
                            <span class="font-size-18">{{ data.withdrawal.amount }}</span>
                        </div>
                        <div class="flex-1 flex-row">
                            <span class="color-9 font-size-12">提现中(元)：</span>
                            <span class="font-size-18">{{ data.withdrawal.wait }}</span>
                        </div>
                        <div class="flex-1 flex-row">
                            <span class="color-9 font-size-12">已完成(元)：</span>
                            <span class="font-size-18">{{ data.withdrawal.done }}</span>
                        </div>
                        <div class="flex-1 flex-row">
                            <span class="color-9 font-size-12">已驳回(元)：</span>
                            <span class="font-size-18">{{ data.withdrawal.reject }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-box__chart flex-1 bg-white box-radius padded-10 margin-t-10 flex-column">
            <div class="box-title">
                <div class="flag"></div>
                <span class="">14天转发走势图</span>
            </div>
            <div id="lineBox" class="margin-t-10 flex-1">
                <div id="line" ref="line" :style="lineStyle"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    name: "Welcome",
    data: function () {
        return {
            lineStyle: {
                width: '100%',
                height: '0'
            },
            pieInstance: null,
            lineInstance: null,
            data: {
                base: {site: "总站", service: "--", income: -45.36, balance: "--", withdrawal: "--"},
                merchant: {all: 4, sharing: 2, welling: 1, done: 1, set_meal: 2, shared: 368, share: 232},
                order: {member: 1, commission: 4.64, member_commission: 50, platform_commission: -45.36},
                withdrawal: {count: 1, amount: 10, wait: 10, done: 0, reject: 0},
                chart: {
                    legend: [],
                    data: []
                }
            }
        }
    },
    activated() {
        this.calcChartHeight()
    },
    methods: {
        calcChartHeight: function () {
            this.lineStyle.height = document.getElementById('lineBox').offsetHeight + 'px'
            this.getData()
        },
        initChart: function () {
            this.lineInstance = echarts.init(document.getElementById('line'));
            this.lineInstance.setOption({
                visualMap: [
                    {
                        show: false,
                        type: 'continuous',
                        seriesIndex: 0,
                        min: 0,
                        max: 400
                    },
                ],
                title: [
                    {
                        show: false,
                        left: 'center',
                        text: 'Gradient along the y axis'
                    }
                ],
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: [
                    {
                        data: this.data.chart.legend
                    }
                ],
                yAxis: [
                    {},
                ],
                grid: [
                    {
                        bottom: '5%',
                        left: '2%',
                        right: '2%'
                    }
                ],
                series: [
                    {
                        type: 'line',
                        showSymbol: false,
                        data: this.data.chart.data
                    }
                ]
            });
        },
        getData: function () {
            this.$helper._get(this, 'league.Analysis.welcome', {}, resp => {
                this.data = resp.data
                this.initChart()
            })
        },
    }
}
</script>

<style scoped lang="scss">
.box {
    .bg-white {
        background-color: white;
    }

    .bg-dark {
        background-color: #202133;
    }

    .box-radius {
        border-radius: 5px;
    }

}

.box-title {
    display: flex;
    align-items: center;

    .flag {
        border-left: 3px solid #F25200;
        height: 12px;
        margin-right: 8px;
    }
}

.left-box {
    flex: 3;

    .left-box__top {
        height: 257px;

        .order-item {
            padding: 14px 15px;
            border-top: 1px solid #f8f8f8;

            .status {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.right-box {
    flex: 15;
    margin-left: 10px;

    .right-box__top .item-box {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    .right-box__order {
        .right-box__order-item {

            .num {
                color: #F25200;
                font-size: 16px;
            }

            .title {
                color: #a9a9a9;
                margin-top: 5px;
            }
        }

        .cut {
            border-right: 1px solid #666666;
            height: 15px;
        }
    }
}

.bottom-box__chart {
    background-color: white;
    border-radius: 5px;
}
</style>
